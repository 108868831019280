<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-update-btn 
            name="updateBtn"
            :data="param.regUpdateBtnData"
            :btnEditable="btnEditable"
            :flagCondition="flagCondition"
            @back="back"
          />
          <c-btn v-show="isOld && !updateDisabled" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" />
          <!-- 완료 -->
          <c-btn 
            v-show="isOld && !updateDisabled" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="accData"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completeAccident"
            @btnCallback="completeCallback" />
          <!-- 저장 -->
          <c-btn
            v-show="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="accData"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 사고명 -->
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="LBL0002306"
            name="accidentName"
            v-model="accData.accidentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사고구분 -->
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentTypeCd"
            label="사고구분"
            v-model="accData.accidentTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고종류 -->
          <c-multi-select
            required
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCd"
            label="LBL0002301"
            v-model="accData.accidentKindCd"
          >
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생일시 -->
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="LBL0002309"
            name="occurrenceDate"
            v-model="accData.occurrenceDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공정 -->
          <c-process
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="LBLPROCESS"
            name="processCd"
            v-model="accData.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생장소 -->
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="LBL0002320"
            name="occurrenceLocation"
            v-model="accData.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 보고자 -->
          <c-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="accData"
            label="LBL0002321"
            type="dept_user"
            name="reportUserId"
            v-model="accData.reportUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 보고일시 -->
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            type="datetime"
            label="LBL0002322"
            :minuteStep="10"
            name="reportDate"
            v-model="accData.reportDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사고등급 -->
          <c-select
            :editable="editable"
            :disabled="disabled"
            type="edit"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentGradeCd"
            label="LBL0002308"
            v-model="accData.accidentGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="accData.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사고번호 -->
          <c-text
            :editable="editable"
            :disabled="true"
            label="LBL0002305"
            name="accidentNo"
            v-model="accData.accidentNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생부서 -->
          <c-dept
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="LBL0002302"
            name="occurrenceDeptCd"
            v-model="accData.occurrenceDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 날씨 -->
          <c-radio
            :editable="editable"
            :disabled="disabled"
            :comboItems="weatherItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="weather"
            label="LBL0002323"
            v-model="accData.weather"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 긴급조치사항 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="6"
            label="LBL0002324"
            name="emergencyMeasures"
            v-model="accData.emergencyMeasures">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 사고사항개요 -->
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :isCheck="true"
            :rows="6"
            label="LBL0002325"
            name="overviewAccidentSituation"
            v-model="accData.overviewAccidentSituation"
            @checkboxClick="checkboxClick"
            >
          </c-textarea>
        </div>
      </template>
    </c-card>
    <!-- 사고원인 -->
    <c-card title="사고원인" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 대분류 -->
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="occFirstItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeLargeCd"
            label="LBL0002327"
            v-model="accData.occurrenceModeLargeCd"
            @datachange="setSecondItems"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 중분류 -->
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="occSecItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeSmallCd"
            label="LBL0002328"
            v-model="accData.occurrenceModeSmallCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 인적피해여부 -->
          <c-radio
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="humanDamageItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="humanDamage"
            label="LBL0002329"
            v-model="accData.humanDamage"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 직접원인 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002330"
            name="directCause"
            v-model="accData.directCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 간접원인 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002331"
            name="indirectCause"
            v-model="accData.indirectCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 기인물 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002332"
            name="openning"
            v-model="accData.openning">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 가해물 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="LBL0002333"
            name="injurious"
            v-model="accData.injurious">
          </c-text>
        </div>
      </template>
    </c-card>
    
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-register-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        uid: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
  },
  data() {
    return {
      accData: {
        iimAccidentId: '',
        accidentTypeCd: null,
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: 'Y',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        processCd: '',

        regUserId: '',
        chgUserId: '',
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [
        { code: 'WC000001', codeName: '맑음' },
        { code: 'WC000002', codeName: '흐림' },
        { code: 'WC000003', codeName: '비' },
        { code: 'WC000004', codeName: '눈' },
      ],
      humanDamageItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '미해당' },
      ],
      editable: true,
      saveUrl: '',
      completeUrl: '',
      saveType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.accData.iimAccidentId)
    },
    updateDisabled() {
      return Boolean(this.accData.accidentStatusCd) && this.accData.accidentStatusCd !== 'ISPC000001'
    },
    disabled() {
      return Boolean(this.accData.accidentStatusCd) && this.accData.accidentStatusCd !== 'ISPC000001' && !this.param.regUpdateBtnData.flag
    },
    btnEditable() {
      return this.editable && (this.accData.accidentStatusCd !== 'ISPC000005' && this.accData.accidentStatusCd !== 'ISPC000001') && Boolean(this.accData.iimAccidentId)
    },
    flagCondition() {
      return this.accData.accidentStatusCd === 'ISPC000001' 
    },
  },
  watch: {
    'param.regUpdateBtnData.planResearch'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.saveUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.process.complete.url;
      // list setting
      let promises = [
        {
          func: this.getDetail,
        },
        {
          func: this.setFirstItems
        },
        {
          func: this.setSecondItems
        },
      ];
      this.$comm.orderedPromise(promises);
    },
    getDetail() {
      return new Promise(resolve => {
        if (this.param.iimAccidentId) {
          this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.$emit('setRegInfo', _result.data)
            this.accData = _result.data;
            resolve(true);
          },);
        } else {
          this.accData.reportUserId = this.$store.getters.user.userId
          resolve(true);
        }
      });
    },
    setFirstItems() {
      return new Promise(resolve => {
        this.$http.url = selectConfig.sop.iim.occurForm.list.url;
        this.$http.type = 'GET';
        this.$http.param = {
          useFlag: 'Y',
          occurLevel: '1',
        }
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.occFirstItems.splice(0, 0, {
              code: _item.iimOccurMasterId,
              codeName: _item.occurName,
            })
          })
          resolve(true);
        },);
      });
    },
    setSecondItems() {
      return new Promise(resolve => {
        if (this.accData.occurrenceModeLargeCd) {
          this.$http.url = selectConfig.sop.iim.occurForm.list.url;
          this.$http.type = 'GET';
          this.$http.param = {
            iimUpOccurMasterId: this.accData.occurrenceModeLargeCd,
            useFlag: 'Y',
            occurLevel: '2',
          }
          this.$http.request((_result) => {
            this.occSecItems = this.$_.map(_result.data, item => {
              return {
                code: item.iimOccurMasterId,
                codeName: item.occurName,
              }
            });
            if (this.$_.findIndex(this.occSecItems, { code: this.accData.occurrenceModeSmallCd }) === -1) {
              this.accData.occurrenceModeSmallCd = null;
            }
            resolve(true)
          },);
        } else {
          this.occSecItems = [];
          this.accData.occurrenceModeSmallCd = null;
          resolve(true)
        }
      })
    },
    saveAccident() {
      if (this.param.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accData.regUserId = this.$store.getters.user.userId
              this.accData.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.param, 'iimAccidentId', result.data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: this.param.iimAccidentId
      })
      this.getDetail();
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.accData.overviewAccidentSituation = 
          '- ' +
          '누가 : \n' + // 누가
          '- ' +
          '누구에게 : \n' + // 누구에게
          '- ' +
          '언제 : \n' + // 언제
          '- ' +
          '어디서 : \n' + // 어디서
          '- ' +
          '무엇을 : \n' + // 무엇을
          '- ' +
          '어떻게 : \n' + // 어떻게
          '- ' +
          '왜 : ';
      } else {
        this.accData.overviewAccidentSituation = '';
      }
    },
    completeAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSG0000701', // 사고등록 하시겠습니까?(저장과 함께 등록됩니다.)
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accData.accidentStatusCd = 'ISPC000003'
          this.accData.regUserId = this.$store.getters.user.userId
          this.accData.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.accData.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.accData.accidentStatusCd 
        })
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.accData.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>
